<template>
  <div class="auth-form-component">
    <FormItem v-if="showAuth" labelWidth="90px" :form="data" :cols="authCols" />

    <FormItem v-if="showPersonnal" labelWidth="90px" :form="data" :cols="authColsInfo" />

    <FormItem v-if="showDistributor" labelWidth="90px" :form="data" :cols="distributorCols">
      <template #nickName="{ scoped: { prop } }"> {{ data.userDTO && data.userDTO[prop] }} </template>
      <template #accountName="{ scoped: { prop } }">
        {{ data.userDTO && data.userDTO[prop] }}
      </template>
    </FormItem>
    <FormItem v-if="showEnterprise" labelWidth="120px" :form="data" :cols="concatEnterpriseCols">
      <template #businessLicenceFilePath>
        <el-image
          style="width: 60px"
          :src="aLiCompressPicByUrl(data.businessLicenceFilePath, 400)"
          :preview-src-list="[data.businessLicenceFilePath]"
        />
        <DownBusinessLicenceFilePath :data="data" />
      </template>

      <template #manualApproveStatusGroup>
        <ValidPassForm :data="data" v-on="$listeners" />
      </template>
    </FormItem>
    <FormItem v-if="showPassExamine" labelWidth="90px" :form="data" :cols="passExamineCols">
      <template #isCertificated>
        {{ data.isCertificated === 1 ? '审核通过' : '审核失败' }}
      </template>
    </FormItem>
    <FormItem v-if="showFailExamine" labelWidth="90px" :form="data" :cols="failExamineCols">
      <template #isCertificated>
        {{ data.isCertificated === 1 ? '审核通过' : '审核失败' }}
      </template>
    </FormItem>
    <ChangeAuthExamin v-if="showChangeAuthExamin" :data="data" v-on="$listeners" />
  </div>
</template>

<script>
import FormItem from './module/formItem'
import DownBusinessLicenceFilePath from './module/downBusinessLicenceFilePath'
import ValidPassForm from './module/validPassForm'
import ChangeAuthExamin from './module/changeAuthExamin'

import { authCols, distributorCols, enterpriseCols, passExamineCols, failExamineCols, authColsInfo } from './const.js'

export default {
  components: {
    FormItem,
    DownBusinessLicenceFilePath,
    ValidPassForm,
    ChangeAuthExamin
  },

  props: {
    data: {
      type: [Object, Array],
      required: true
    },

    cols: {
      type: Array,
      default: () => []
    },

    showAuth: Boolean, //展示企业认证信息
    showDistributor: Boolean, //展示分销商信息
    showEnterprise: Boolean, //展示公司信息
    showPassExamine: Boolean, //展示审核成功信息
    showFailExamine: Boolean, //展示审核失败
    showChangeAuthExamin: Boolean, //展示修改审核结果信息
    showOriginBusinessLicenceFilePath: Boolean, //展示显示原图信息
    showValidPass: Boolean, //展示审核信息
    showPersonnal: Boolean, //展示个人信息
    hasDownBusinessLicenceFilePath: Boolean //下载附件的权限
  },

  data() {
    return {
      authCols,
      distributorCols,
      enterpriseCols,
      passExamineCols,
      failExamineCols,
      authColsInfo
    }
  },

  computed: {
    concatEnterpriseCols({ enterpriseCols, showValidPass }) {
      const concatArr = showValidPass
        ? [
            {
              prop: 'manualApproveStatus',
              label: '审核'
            }
          ]
        : []
      return [...enterpriseCols, ...concatArr]
    }
  }
}
</script>

<style lang="scss" scoped>
.right-content-wrapper {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  .right-content_label {
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>
