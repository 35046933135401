<template>
  <div class="form-container">
    <div class="left-content">
      <div class="item" :key="index" v-for="(item, index) in cols">
        <slot :name="`${item.prop}Group`">
          <span :style="lableStyle" class="label"> {{ item.label }}：</span>
          <slot :name="item.prop" :scoped="item">
            <span class="name" v-if="!item.dicData">
              <span v-if="item.prop === 'certificateType'">{{form[item.prop] === 1?'个人用户':'企业用户'}}</span>
              <span v-else>{{ form[item.prop] }}</span>
            </span>
            <span v-else>
               {{ getLabel(item.prop,form[item.prop]) }}
            </span>
          </slot>
        </slot>
      </div>
    </div>
    <slot name="rightContent"> </slot>
  </div>
</template>

<script>
import { getLabel } from '@/components/avue/utils/util'
export default {
  props: {
    labelWidth: {
      type: String,
      default: '80px'
    },

    form: {
      type:[Object, Array],
      required: true
    },

    cols: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    lableStyle({ labelWidth }) {
      return {
        width: labelWidth,
        flex: `0 0 ${labelWidth}`
      }
    }
  },

  methods: {
    getLabel
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px dashed $border-color;
}
.left-content {
  flex: 1;
}
.item {
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
}
.label {
  display: inline-block;
  margin-right: 10px;
  text-align: justify;
  text-align-last: justify;
  font-size: 14px;
  color: #606266;
}
</style>