import store from '@/store'
// 个人认证信息
export const authCols = [
  {
    label: '认证状态',
    prop: 'isCertificated',
    dicData:store.getters.isCertificated
  },
  {
    label: '认证类型',
    prop: 'certificateType'
  },
  {
    prop: 'certificateTime',
    label: '认证时间'
  }
]
export const authColsInfo = [
  {
    label: '真实姓名',
    prop: 'realName'
  },
  {
    label: '身份证号',
    prop: 'idNumber'
  },
  {
    label: '手机号码',
    prop: 'mobile'
    
  }
]


// 分销商信息
export const distributorCols = [
  {
    label: '分销商名称',
    prop: 'nickName'
  },
  {
    label: '分销商账号',
    prop: 'accountName'
  },
  {
    prop: 'certificateTime',
    label: '提交时间'
  }
]

// 公司信息
export const enterpriseCols = [
  {
    label: '企业名称',
    prop: 'companyName'
  },
  {
    label: '统一信用代码',
    prop: 'unifiedCreditCode'
  },
  {
    prop: 'businessLicenceFilePath',
    label: '营业执照附件'
  },
  {
    label: '法人姓名',
    prop: 'legalPersonName'
  },
  {
    label: '法人身份证号码',
    prop: 'legalPersonIdNumber'
  },
  {
    prop: 'legalPersonMobile',
    label: '法人手机号码'
  }
]

// 审核成功信息
export const passExamineCols = [
  {
    label: '审核人员',
    prop: 'approveUserName'
  },
  {
    label: '审核结果',
    prop: 'isCertificated'
  },
  {
    prop: 'manualApproveTime',
    label: '审核时间'
  }
]

// 审核失败
export const failExamineCols = [
  {
    label: '审核人员',
    prop: 'approveUserName'
  },
  {
    label: '审核结果',
    prop: 'isCertificated'
  },
  {
    label: '失败原因',
    prop: 'manualApproveOpinion'
  },
  {
    prop: 'manualApproveTime',
    label: '审核时间'
  }
]