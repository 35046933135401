<template>
  <!-- 下载营业执照 -->
  <ColorTextBtn size="mini" @click="downExpressWaybillFile(data)">下载</ColorTextBtn>
</template>

<script>
import { downloadImageByAixos } from '@/utils/download'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  methods: {
    //下载面单
    downExpressWaybillFile(row) {
      const { businessLicenceFilePath } = row
      if (!businessLicenceFilePath) {
        this.$message.error('地址不存在')
        return
      }
      downloadImageByAixos(businessLicenceFilePath)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-button {
 ::v-deep {
    span {
    position: relative;
    top: 23px;
    left: 10px;
  }
 }
}
</style>